.button {
  display: inline-block;
  font-family: $body-font;
  padding: 18px 34px;
  margin: 0;
  border: none;
  color: #ffffff;
  border-radius: 2px;
  background: $color-main;
  -webkit-appearance: none;
  text-decoration: none;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  text-transform: uppercase;
  transition: ease-in-out 0.1s;

  &.btn--light {
    background: $color-white;
    color: $color-second;
  }

  &.btn--sm {
    padding: 10px 16px;
    font-size: 13px;
    line-height: 16px;
  }

  &.btn--md {
    padding: 16px 22px;
    font-size: 15px;
    line-height: 16px;
  }

  &.btn--lg {
    padding: 18px 34px;
    font-size: 16px;
    line-height: 18px;

    @media (min-width: 992px) {
      font-size: 16px;
    }
  }

  &:hover {
    background: $color-second;

    &.btn--light {
      background: $color-second;
      color: $color-white;
    }
  }
}