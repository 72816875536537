.swiper {
    width: 100%;
    height: 375px;

    @media (min-width: 992px) {
      height: calc(100vh - 255px)
    }

    &-slide {
      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center 20%;
      }
    }

    &__button {
      position: relative;
      width: 50px;
      height: 50px;

      &.btn--prev {
        left: 25px;

        @media (min-width: 992px) {
          left: 45px;
        }

        @media (min-width: 1200px) {
          left: 120px;
        }
      }

      &.btn--next {
        right: 25px;

        @media (min-width: 992px) {
          right: 45px;
        }

        @media (min-width: 1200px) {
          right: 120px;
        }
      }

    }
}