.services {
    padding: 60px 0 30px 0;

    @media (min-width: 992px){
        padding: 90px 0;
    }

    &__item {
        margin-bottom: 30px;

        @media (min-width: 992px){
            margin-bottom: 0;
        }

    }

    &__title {
        margin-top: 20px;
        margin-bottom: 10px;

        @media (min-width: 992px){
            margin-bottom: 10;
        }
    }
}