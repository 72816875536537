html,
body {
  margin: 0;
  padding: 0;
  background: #ffffff;
}

a {
  text-decoration: none;
  color: $color-main;
  font-family: $body-font;
}

.no-scroll {
  overflow: hidden;
  color: #ffffff;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

@media (max-width: 992px) {
  .hide-mobile {
    display: none !important;
  }
}

@media (min-width: 992px) {
  .hide-desktop {
    display: none !important;
  }
}
