.footer {
    padding: 50px 0;
    border-top: $border-standard;

    &__inner {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__text {
        font-size: 12px;
        color: $color-black;

        @media (min-width: 992px) {
            font-size: 14px;
        }
    }
}