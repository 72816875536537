.certificaat {
    background: $color-main;

    &__right {
        margin-left: 0;
        margin-top: 30px;

        @media (min-width: 992px) {
            margin-left: 30px;
            margin-top: 0;
        }
    }

    &__title {
        margin-bottom: 15px;
    }
}