.service-page {

    &__image {
        margin-bottom: 30px;
    }

    h3 {
        margin-bottom: 10px; 
    }

    h4 {
        margin-top: 20px;
        margin-bottom: 10px;

        @media (min-width: 992px) {
            margin-top: 30px;
        }  
    }

    &--intro {
        font-size: 25px!important;
        line-height: 1.7!important;
        margin-bottom: 0 !important;

        @media (min-width: 992px) {
            font-size: 24px!important;
            margin-bottom: -10px !important;
        }
    }

    p {
        margin-bottom: 20px;
        line-height: 1.7;
        font-size: 16px;
        color: $color-second;
    }

    ul {
        margin-top: 20px;
        margin-bottom: 20px;

        li {
            margin-left: 20px;
            font-size: 16px;
            line-height: 1.6;
            margin-bottom: 10px;
            color: $color-second;
    
            &::before {
                content: "";
                position: absolute;
                width: 10px;
                height: 10px;
                border-radius: 50%;
                background-color: $color-main;
                left: 25px;
                margin-top: 7px;

                @media (min-width: 992px) {
                    left: 15px;
                }

            }
        }
    }
}