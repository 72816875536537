.about {
    padding: 30px 0 60px 0;

    @media (min-width: 992px) {
        padding: 90px 0;
    }

    &__image {
        margin-bottom: 30px;
        margin-top: 30px;

        @media (min-width: 992px) {
            margin-top: 0;
        }
    }

    &__title {
        margin-bottom: 10px;
    }
}