.contact {
&__form {
    display: flex;
    flex-direction: column;

    &__col-2 {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
    }

    &__field {
        width: 100%;
        margin-bottom: 5px;

        &:first-child {
            margin-right: 20px;
        }

        &--name {
            font-family: $main-font;
            font-weight: 400;
        }

        &--ph {
            font-family: $body-font;
            font-size: 15px;
            padding: 16px 16px 16px 16px;
            border: 2px solid #EBEDEF;
            width: 100%;
            margin-bottom: 25px;
            margin-top: 15px;
            background-color: transparent;

            &:focus{
                border: 2px solid $color-second;
            }
        }

        &--select {
            font-family: $body-font;
            font-size: 15px;
            border: 2px solid #EBEDEF;
            width: 100%;
            margin-bottom: 25px;
            margin-top: 15px;
            background-color: transparent;

            display: flex;
            justify-content: space-between;
            align-items: center;

            & select {
                width: 100%;
                padding: 18px 16px;
                border: none;
                -webkit-appearance: none;
            }

            &:focus{
                border: 2px solid $color-second;
            }

            & .select-icon {
                width: 20px;
                height: 20px;
                position: absolute;
                right: 40px;

                @media (min-width: 992px) {
                    right: 30px;
                }
            }
        }
    }

    &__col-1 {
        display: flex;
        flex-direction: column;
    }

    &--message {
        font-family: $body-font;
        font-size: 15px;
        padding: 16px 16px 16px 16px;
        border: 2px solid #EBEDEF;
        width: 100%;
        margin-bottom: 25px;
        margin-top: 10px;
        background-color: transparent;

        &:focus{
            border: 2px solid $color-second;
        }
    }

    &__submit {
        display: flex;
        margin-top: 10px;

        &--btn {
            border: none;
            padding: 0;
            font-family: $body-font;
            font-size: 18px;
            cursor: pointer;
        }

        &--icon {
            position: relative;
            top: 10px;
            width: 58px;
            margin-left: 15px; 
            cursor: pointer;
        }
    }
}
}

textarea:focus, input:focus {
    outline: none;
}

::placeholder {
    color: #BCBCC4;
}

input {
    &:-webkit-autofill,
    &:-webkit-autofill:hover
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
        -webkit-text-fill-color: $color-second !important;
    }
}


