.navBar {
    border: $border-standard;
}

.menu {
    background-color: transparent;
  
    @media (min-width: 992px) {
      background-color: $color-white;
    }
  
    &.contactPage {
      background-color: transparent;
    }
  
    &__wrapper {
      width: 100%;
      position: relative;
    }
  
    &__inner {
      padding: 0;
      height: 80px;
      display: flex;
      align-items: center;
      justify-content: space-between;
  
      @media (min-width: 992px) {
        padding: 0;
        height: auto;
      }
    }
  
    &__logo {
      z-index: 998;
  
      & .logo {
        height: 50px;
        width: auto;

        @media (min-width: 992px) {
          height: 70px;
        }
      }
  
      p {
        font-family: $main-font;
        font-size: 19px;
      }
    }
  
    &__nav {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: $color-beige;
      display: flex;
      align-items: center;
      visibility: hidden;
      opacity: 0;
      z-index: 998;
  
      @media (min-width: 992px) {
        position: relative;
        opacity: 1;
        visibility: visible;
        background-color: transparent;
        display: block;
        width: auto;
        height: auto;
      }
  
      ul {
        margin: auto;
        text-align: center;
  
        @media (min-width: 992px) {
          margin: auto 0;
        }
      }
  
      ul li {
        display: inline-block;
  
        @media (max-width: 992px) {
          display: block;
          margin: 0 0;
        }
  
        & .dropdown {
          display: flex;
          flex-direction: column;
          text-align: left;
          width: auto;
          background: white;
          padding: 12px 25px 12px 25px;
          position: absolute;
          border-radius: 0 0 3px 3px;
          border: $border-standard;
          opacity: 0;
          visibility: hidden;
          transform: translateY(0.5rem);
          transition: all 0.18s;
  
          &__item {
            border-bottom: $border-standard;
  
            &:last-child {
              border-bottom: none;
            }
  
            a {
              font-size: 14px;
              margin: 16px 0;
              padding: 0;
              width: 100%;
            }
          }
        }
      }
  
      ul li a {
        display: inline-block;
        padding: 0;
        margin-right: 0;
        color: $color-second;
        font-family: $body-font;
        font-size: 24px;
        font-weight: normal;
        text-decoration: none;
        text-transform: uppercase;
        transition: 0.1s ease-in-out;
  
        @media (min-width: 992px) {
          font-size: 14px;
          padding: 50px 16px;
        }

        &:hover {
          color: $color-main;
        }
      }
  
      & .ml-18 {
        margin-left: 18px;
      }
  
      &--active {
        opacity: 1;
        visibility: visible;
      }
    }
  
    &__toggle {
      display: inline-block;
      width: 30px;
      height: 30px;
      top: 30px;
      right: 30px;
      cursor: pointer;
      background-image: url("/assets/img/svg/mobile-menu-01.svg");
      background-repeat: no-repeat;
      background-size: cover;
      opacity: 1;
      transition: transform 0.25s ease-out 0s, opacity 0.215s ease-in-out 0s;
      z-index: 999;
  
      @media (min-width: 992px) {
        display: none;
      }
    }
  
    &__toggle--close {
      position: absolute;
      top: 25px;
      right: 25px;
      background-image: url("/assets/img/svg/mobile-menu-02.svg");
      transform: rotate(180deg);
      width: 30px;
      height: px;
      opacity: 1;
      transition: transform 0.25s ease-out 0s, opacity 0.215s ease-in-out 0s;
      z-index: 999;
    }
  }
  
  .submenu:hover .dropdown {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }
  
  // menu mobile
  .menuMobile {
    position: absolute;
    width: 100%;
    height: auto;
    margin-top: 80px;
    border-top: $border-standard;
    border-bottom: $border-standard;
  
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  
    background: $color-white;
    padding: 35px 25px;
    z-index: 998;
    top: 0;
    opacity: 0;
    visibility: hidden;
    margin-left: -1px;
  
    &--open {
      opacity: 1;
      visibility: visible;
    }
  
    &__header {
      p {
        color: $color-black;
        font-family: $main-font;
        font-size: 19px;
      }
    }
  
    &__nav {
      margin-bottom: 5px;

      &--item {
        margin-bottom: 20px;
        cursor: pointer;
        color: $color-black;
        font-family: $main-font;
        font-size: 21px;
  
        a {
          color: $color-black;
          font-family: $main-font;
          font-size: 21px;
        }
  
        & .dropdownMobile {
          margin: 0;
          opacity: 0;
          visibility: hidden;
          transition: all 0.25s ease;
          height: 0;
  
          &--open {
            margin: 25px 0 25px 0;
            opacity: 1;
            visibility: visible;
            height: 100%;
          }
  
          &__item {
            margin-bottom: 15px;
            margin-left: 20px;
  
            a {
              color: $color-black;
              font-size: 18px;
              cursor: pointer;
              font-family: $body-font;
  
              &:active {
                color: $color-black;
              }
            }
          }
        }
      }
    }
  
    &__footer {
      p {
        color: $color-black;
        opacity: 0.5;
        font-size: 14px;
      }
    }
  }
  