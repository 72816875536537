.shockwave {

    &__left {
        margin-top: 30px;
        margin-right: 0;

        @media (min-width: 992px) {
            margin-top: 0;
            margin-right: 30px;
        }
    }

    &__title {
        margin-bottom: 15px;
    }

    &__body {
        margin-bottom: 30px;
    }
}