.header {
    padding: 180px 0;
    background: palegoldenrod;

    h1 {
        font-size: 67px;
        line-height: 1.3;
        color: palevioletred;
        margin-bottom: 25px;
    }
}