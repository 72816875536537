p,
em,
strong,
blockquote,
span,
li {
  margin: 0;
  color: $color-black;
  font-family: $body-font;
  font-weight: 300;
  font-style: normal;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-family: $main-font;
  font-weight: 700;
  font-style: normal;
  color: $color-second;
}

p {
  line-height: 1.7;
  font-size: 16px;
  color: #818B92;
  font-weight: 300;
}

h1 {
  font-size: 35px;
  line-height: 1.2;

  @media (min-width: 992px) {
    font-size: 60px;
    line-height: 1.2;
  }
}

h2 {
  font-size: 28px;
  line-height: 1.2;

  @media (min-width: 992px) {
    font-size: 37px;
    line-height: 1.33;
  }
}

h3 {
  font-size: 24px;
  line-height: 1.2;

  @media (min-width: 992px) {
    font-size: 28px;
    line-height: 1.33;
  }
}

h4 {
  font-size: 21px;
  line-height: 1.33;
}

h5 {
  font-size: 18px;
  line-height: 1.2;

  @media (min-width: 992px) {
    font-size: 20px;
    line-height: 1.33;
  }
}

h6 {
  font-size: 18px;
  line-height: 1.3;

  @media (min-width: 992px) {
    font-size: 28px;
  }
}

em {
  font-style: italic;
}

strong {
  font-weight: 700;
}

blockquote {
  font-size: 1.2em;
  font-weight: 700;
  font-style: italic;
}

.typ-med {
  font-size: 2em;
  font-weight: 300;

  @media (max-width: 767px) {
    font-size: 1.5em;
  }
}

.typ-big {
  font-size: 3em;
  line-height: 1.7;

  @media (max-width: 767px) {
    font-size: 2em;
  }
}

.highlighted {
  position: relative;
  z-index: 0;

  &::after {
    position: absolute;
    content: "";
    bottom: -5px;
    left: 0;
    height: 5px;
    width: 100%;
    background: $color-black;
    z-index: -1;
  }
}
