.widget {
    margin-left: 0;
    border-top: $border-standard;
    margin-top: 30px;

    @media (min-width: 992px) {
        margin-left: 30px;
        border-top: none;
        margin-top: 0;
    }

    p {
        margin-bottom: 20px;
        line-height: 1.7;
        font-size: 16px;
        color: $color-second;
    }

    &__item {
        margin-bottom: 30px; 
    }

    &__title {
        margin-top: 0!important;
        margin-bottom: 10px;

        &--first {
            margin-top: 40px!important;

            @media (min-width: 992px) {
                margin-top: 0!important;
            }
        }
    }
}